import GLightbox from "glightbox";

/*var lightboxInlineIframe = GLightbox({
    selector: '.glightbox'
});
*/

window.GLightbox = GLightbox;

document.addEventListener("DOMContentLoaded", function () {
    let lightboxElements = document.querySelectorAll(".glightbox");

    lightboxElements.forEach((element) => {
        GLightbox({
            selector: "#" + element.getAttribute("id"),
        });
    });
});
